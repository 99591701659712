import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Tooltip,
  Box,
  Button,
  MenuItem,
  Modal,
  Container,
} from "@mui/material";
import DataSerices from "../Services/DataSerices";
import MUIDataTable from "mui-datatables";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import BackDropLoading from "../Components/Loading";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import moment from "moment/moment";
import dayjs from "dayjs";

const statusList = [
  {
    alertType: "Loitering",
    question: "Do you see anyone loitering?",
    options: ["Yes", "No"],
  },
  {
    alertType: "Illegal Dumping",
    question: "Do you see anyone dumping illegally?",
    options: ["Yes", "No"],
  },
  /* {
    alertType: "Perimeter Monitoring",
    question: "Do you see anyone crossing perimeter?",
    options: ["Yes", "No"],
  },
  {
    alertType: "Illegal Parking",
    question: "Do you see any vehicles parked illegally?",
    options: ["Yes", "No"],
  }, */
  {
    alertType: "Pets",
    question: "Do you see any pets loitering?",
    options: ["Yes", "No"],
  },
  {
    alertType: "Person",
    question: "Do you know this person?",
    options: ["Yes", "No"],
  },
  {
    alertType: "People Gathering",
    question: "Is this People Gathering allowed?",
    options: ["Yes", "No"],
  },
];

const AlertMonitoringReport = () => {
  const [list, setList] = useState([]);
  const [duplicateList, setDuplicateList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchByAlert, setSearchByAlert] = useState("");
  const [searchByChannel, setSearchByChannel] = useState("");
  const [channelList, setChannelList] = useState([]);
  const [imageURL, setImageURL] = useState("");
  const [OverlayOpen, setOverlayOpen] = useState(false);
  const [value, setValue] = useState([null, null]);
  const [statusVal, setStatusVal] = useState("");

  const handleAlertTypeChange = (e) => {
    setSearchByAlert(e);
  };
  const handleChannelChange = (e) => {
    setSearchByChannel(e);
  };

  const handleStatusChange = (e) => {
    setStatusVal(e);
  };

  function CustomToolbarComponent({
    alertType,
    channel,
    handleReset,
    handleAlertTypeChange,
    handleChannelChange,
    channelList,
    handleStatusChange,
    statusVal,
  }) {
    const handleSelect = (e) => {
      handleAlertTypeChange(e.target.value);
    };

    const handleChannelSelect = (e) => {
      handleChannelChange(e.target.value);
    };

    const handleStatusChanges = (e) => {
      handleStatusChange(e.target.value);
    };

    const status = ["OPEN", "ISSUERAISED", "RESOLVED"];

    return (
      <Box sx={{ width: "90%", float: "right" }}>
        <Grid container spacing={1} sm={12} xs={12}>
          <Grid item sm={4.5} xs={5.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateRangePicker
                value={value}
                onChange={(e) => setValue([...e])}
                slotProps={{
                  layout: {
                    sx: {
                      margin: 0,
                    },
                  },
                }}
                slots={{
                  fieldSeparator: React.Fragment,
                  textField: (params) => (
                    <TextField
                      {...params}
                      id="outlined-select-currency"
                      size="small"
                      style={{}}
                    ></TextField>
                  ),
                }}
                localeText={{ start: "From date", end: "To date" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item sm={2.6} xs={3}>
            <TextField
              id="outlined-select-currency"
              select
              size="small"
              label="Alert Type"
              value={alertType}
              style={{}}
              fullWidth
              onChange={(e) => handleSelect(e)}
            >
              {statusList.map((option) => (
                <MenuItem key={option.alertType} value={option.alertType}>
                  {option.alertType}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={2} xs={3}>
            <TextField
              id="outlined-select-currency"
              select
              size="small"
              label="Channel"
              value={channel}
              style={{}}
              fullWidth
              onChange={(e) => handleChannelSelect(e)}
            >
              {channelList.length > 0 &&
                channelList.map((option) => (
                  <MenuItem key={option.channel} value={option.channel}>
                    {option.channel}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item sm={2} xs={3}>
            <TextField
              id="outlined-select-currency"
              select
              size="small"
              label="Status"
              value={statusVal}
              style={{}}
              fullWidth
              onChange={(e) => handleStatusChanges(e)}
            >
              {status.length > 0 &&
                status.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item sm={0.5} xs={0.5}>
            <Tooltip title="Reset">
              <IconButton onClick={() => handleReset()}>
                <RestartAltIcon style={{ color: "white" }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const handleReset = (e) => {
    setPage(0);
    setPageSize(10);
    setSearchByAlert("");
    setSearchByChannel("");
    setValue([null, null]);
    setStatusVal("");
  };

  const options = {
    filter: false,
    //filterType: 'textField',
    selectableRows: false,
    search: false,
    serverSide: true,
    download: true,
    print: false,
    viewColumns: false,
    pagination: true,
    count: count,
    page: page,
    rowsPerPage: pageSize,
    rowsPerPageOptions: [10, 25, 50],
    responsive: "scrollFullHeight",
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          if (list.length > 9) {
            setPage(tableState.page);
          }
          break;
        case "changeRowsPerPage":
          setPageSize(tableState.rowsPerPage);
          break;
        case "filterChange":
          setSearchByAlert(tableState.filterList[2][0]);
          setSearchByChannel(tableState.filterList[3][0]);
          break;
        case "resetFilters":
          setSearchByAlert("");
          setSearchByChannel("");
          break;
      }
    },
    customToolbar: () => {
      return (
        <CustomToolbarComponent
          alertType={searchByAlert}
          channel={searchByChannel}
          handleAlertTypeChange={handleAlertTypeChange}
          handleChannelChange={handleChannelChange}
          channelList={channelList}
          handleReset={handleReset}
          handleStatusChange={handleStatusChange}
          statusVal={statusVal}
        />
      );
    },
  };

  const columns = [
    {
      name: "id",
      label: "S/No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowIndex + 1 + page * pageSize}</span>;
        },
      },
    },
    {
      name: "createdDateTime",
      label: "Date & Time",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>
              {moment.utc(value).local().format("YYYY-MM-DD HH:mm:ss")}
            </span>
          );
        },
      },
    },
    {
      name: "alertType",
      label: "Alert Type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "channel",
      label: "Channel",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "imageUrl",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  getItemDetails(value);
                }}
              >
                View
              </Button>
            </>
          );
        },
      },
    },
  ];

  const getItemDetails = async (data) => {
    try {
      if (data) {
        setImageURL(data);
      } else {
        setImageURL("");
      }
      setOverlayOpen(true);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getChannelList();
  }, []);

  const getChannelList = async () => {
    const storedUserDetails = sessionStorage.getItem("userDetails");
    var dummy = [];
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);

      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
      await DataSerices.getChannelList(dummy[0].value)
        .then((res) => {
          setChannelList(res?.data?.response?.body);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  useEffect(() => {
    getList();
  }, [page, pageSize, searchByAlert, searchByChannel, statusVal, value]);

  // useEffect(() => {
  //   if (value[0] !== null && value[1] !== null) {
  //     getList();
  //   }
  // }, [value]);

  const getList = async () => {
    setIsLoading(true);
    const storedUserDetails = sessionStorage.getItem("userDetails");
    var dummy = [];
    if (storedUserDetails) {
      const parsedUser = JSON.parse(storedUserDetails);
      parsedUser.organization.map((item, index) => {
        dummy.push({ value: item.orgCode, label: item.orgName });
      });
    }
    let data = {
      page: page,
      size: pageSize,
      alertType: searchByAlert,
      channel: searchByChannel,
      statusVal: statusVal,
      fromDate:
        value[0] !== null
          ? moment(new Date(value[0])).format("YYYY-MM-DD")
          : "",
      toDate:
        value[1] !== null
          ? moment(new Date(value[1])).format("YYYY-MM-DD")
          : "",
      orgName: dummy[0].value,
    };
    await DataSerices.getAlertsDetails(data)
      .then((res) => {
        let response = res?.data?.response?.body?.alerts;
        let totalCount = res?.data?.response?.body?.totalSize;
        setList(response);
        setCount(totalCount);
        setDuplicateList(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setIsLoading(false);
      });
  };

  const handleClose = (e) => {
    setOverlayOpen(false);
    setImageURL("");
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {isLoading && <BackDropLoading open={isLoading} />}
        <Modal
          open={OverlayOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: imageURL.length < 1 ? "65%" : "60%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              style={{ padding: `20px`, width: "100%", maxHeight: "80%" }}
            >
              <IconButton
                aria-label="Close"
                color="inherit"
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
              <Grid item xs={12} sm={12} md={12}>
                <img
                  src={imageURL}
                  style={{ width: "100%", maxHeight: `65vh` }}
                  alt="Wait.."
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Grid item sm={12} xs={12}>
          <MUIDataTable
            title={"Alert Monitoring Reports"}
            data={list}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AlertMonitoringReport;
